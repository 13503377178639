.dialogContent {
  display: grid;
  grid-template-columns: 20% 1fr;
  overflow: hidden;

  .conversationContainer{
    margin: 10px 0px 10px 10px;
    display: grid;
    overflow: hidden;
    grid-template-rows: 90% 10%;

    .conversation{
      overflow-y: auto;
    }
  }
}