:root {
  --primary-main: #f2755f;
  --primary-light: #FEF4F2;
  --primary-dark: #b43721;
  --primary-contrast-text: #ffffff;

  --secondary-main: #6c757d;
  --secondary-light: #f5f6f6;
  --secondary-dark: #3a3c3f;
  --secondary-contrast-text: #ffffff;

  --success-main: #198754;
  --success-light: #f0fdf6;
  --success-dark: #155237;
  --success-contrast-text: #ffffff;

  --info-main: #2196f3;
  --info-light: #eefaff;
  --info-dark: #1a548e;
  --info-contrast-text: #ffffff;

  --warning-main: #ffb020;
  --warning-light: #fff9eb;
  --warning-dark: #dd6702;
  --warning-contrast-text: #ffffff;

  --error-main: #f44336;
  --error-light: #fef3f2;
  --error-dark: #be2217;
  --error-contrast-text: #ffffff;

  --text-primary: #121828;
  --text-secondary: #65748b;
  --text-disabled: #3741517a;

  --grey50: #fafafa;
  --grey100: #f5f5f5;
  --grey200: #eeeeee;
  --grey300: #e0e0e0;
  --grey400: #bdbdbd;
  --grey500: #9e9e9e;
  --grey600: #757575;
  --grey700: #616161;
  --grey800: #424242;
  --grey900: #212121;
  --grey-a100: #f5f5f5;
  --grey-a200: #eeeeee;
  --grey-a400: #bdbdbd;
  --grey-a700: #616161;

  --white: #ffffff;
  --black: #000000;
}

$primaryMain: var(--primary-main);
$primaryLight: var(--primary-light);
$primaryDark: var(--primary-dark);
$primaryContrastText: var(--primary-contrast-text);
$primaryLighter: var(--primary-lighter);

$secondaryMain: var(--secondary-main);
$secondaryLight: var(--secondary-light);
$secondaryDark: var(--secondary-dark);
$secondaryContrastText: var(--secondary-contrast-text);

$successMain: var(--success-main);
$successLight: var(--success-light);
$successDark: var(--success-dark);
$successContrastText: var(--success-contrast-text);

$infoMain: var(--info-main);
$infoLight: var(--info-light);
$infoDark: var(--info-dark);
$infoContrastText: var(--info-contrast-text);

$warningMain: var(--warning-main);
$warningLight: var(--warning-light);
$warningDark: var(--warning-dark);
$warningContrastText: var(--warning-contrast-text);

$errorMain: var(--error-main);
$errorLight: var(--error-light);
$errorDark: var(--error-dark);
$errorContrastText: var(--error-contrast-text);

$textPrimary: var(--text-primary);
$textSecondary: var(--text-secondary);
$textDisabled: var(--text-disabled);

$divider: var(--grey200);

$grey50: var(--grey50);
$grey100: var(--grey100);
$grey200: var(--grey200);
$grey300: var(--grey300);
$grey400: var(--grey400);
$grey500: var(--grey500);
$grey600: var(--grey600);
$grey700: var(--grey700);
$grey800: var(--grey800);
$grey900: var(--grey900);
$greyA100: var(--grey-a100);
$greyA200: var(--grey-a200);
$greyA400: var(--grey-a400);
$greyA700: var(--grey-a700);

$paper: var(--white);
$default: var(--white);

$white: var(--white);
$black: var(--black);

:export {
  primaryMain: $primaryMain;
  primaryLight: $primaryLight;
  primaryDark: $primaryDark;
  primaryContrastText: $primaryContrastText;
  secondaryMain: $secondaryMain;
  secondaryLight: $secondaryLight;
  secondaryDark: $secondaryDark;
  secondaryContrastText: $secondaryContrastText;
  successMain: $successMain;
  successLight: $successLight;
  successDark: $successDark;
  successContrastText: $successContrastText;
  infoMain: $infoMain;
  infoLight: $infoLight;
  infoDark: $infoDark;
  infoContrastText: $infoContrastText;
  warningMain: $warningMain;
  warningLight: $warningLight;
  warningDark: $warningDark;
  warningContrastText: $warningContrastText;
  errorMain: $errorMain;
  errorLight: $errorLight;
  errorDark: $errorDark;
  errorContrastText: $errorContrastText;
  textPrimary: $textPrimary;
  textSecondary: $textSecondary;
  textDisabled: $textDisabled;
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey800: $grey800;
  grey900: $grey900;
  greyA100: $greyA100;
  greyA200: $greyA200;
  greyA400: $greyA400;
  greyA700: $greyA700;
}
