@use '../../../../../../styles/colors.scss';

.card {
  border-radius: 0.3rem;
  padding: 0.3rem;
  background-color: colors.$grey200;
}

.card:hover {
  cursor: pointer;
}

.selectedCard {
  background-color: colors.$primaryMain;
  color: colors.$primaryContrastText;
}

.disabledCard {
  cursor: not-allowed !important;
}
