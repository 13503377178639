#messageTemplateEntity {
  width: 100%;
  margin-top: 0.75rem;
  .messageTemplateEntityContent {
    padding-bottom: 0.5rem !important;
  }

  .messageTemplateEntityAction {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    justify-content: space-between;
  }
}

#options {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  & > div {
    cursor: pointer;
  }
  .option {
    padding: 0.5rem 0.75rem;
    .optionContent {
      width: fit-content;
      text-wrap: nowrap;
    }

    transition: 300ms ease-in-out all;
    &:hover {
      transform: scale(1.03);
    }
  }
}
