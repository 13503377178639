#casaAISuggestions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 0.8rem;
  margin-bottom: -1rem;

  div {
    transition: 300ms ease-in-out all;
    cursor: pointer;
  }

  & > div:hover {
    transform: scale(1.03);
  }
}
