@use '../../../../styles/colors.scss';

#sidebarListItem {
  border-radius: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: .35rem;
  .listItemIcon {
    min-width: 2rem;
    margin-right: 0.25rem;
  }
  .arrowIcon {
    transition: 250ms ease-in-out transform;
    &.active {
      transform: rotate(90deg);
    }
  }

  &[aria-current='page'],
  &.activeListItem {
    background-color: colors.$primaryLight;
    .sidebarListItemText {
      color: colors.$primaryMain;
      .sidebarListItemTypography {
        font-weight: 500;
      }
    }
  }

  &.activeListItem {
    margin-left: 2.2rem;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      height: 90%;
      width: 1px;
      top: 50%;
      left: -.5rem;
      transform: translate(-50%, -50%);
      background-color: colors.$primaryMain;
    }
  }
}
