@use "../../../../styles/colors";

#MemberConfig {
  margin-bottom: 2rem;

  .memberColumns {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    gap: 2rem;
  }

  .memberCard {
    padding: 0.5rem;
    margin-bottom: 1rem;
  }

  .periodName {
    display: flex;
    align-items: center;
    margin: 0 0 0 0.5rem;
  }

  .conditionName {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 0 0 0 0.5rem;
  }

  .betweenCondition {
    display: flex;
    gap: 1rem;
  }

  .daysField {
    min-width: 5rem;
    max-width: 10rem;
  }

  .saveButtonContainer {
    display: flex;
    align-items: flex-start;
  }

  .saveButton {
    display: flex;
    justify-content: center;
    margin-top: 0.1rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .deleteButton {
    color: "grey";
    &:hover {
      color: colors.$errorMain;
    }
  }
}
