$breakpoints: (
  xs: 0,
  sm: 576,
  md: 768,
  lg: 892,
  xl: 1200,
);

@mixin create($key) {
  $size: if(map-get($breakpoints, $key), map-get($breakpoints, $key), $key) +
    "px";
  @media only screen and (min-width: $size) {
    @content;
  }
}

:export {
  xs: map-get($breakpoints, xs);
  sm: map-get($breakpoints, sm);
  md: map-get($breakpoints, md);
  lg: map-get($breakpoints, lg);
  xl: map-get($breakpoints, xl);
  xs: map-get($breakpoints, xs);
}
