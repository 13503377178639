#instantSearchDialog {
  .instantSearchFooter {
    padding: 0.5rem 0.85rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .noResultFound {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 2rem;
  }
}
