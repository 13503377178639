@use '../../../styles/breakpoints';

#dashboard {
  .app {
    display: grid;
    @include breakpoints.create(xl) {
      grid-template-columns: 275px 1fr;
    }

    &.fullScreenMode {
      height: 100%;
      grid-template-columns: none !important;
      .container {
        margin-block: 0 !important;
        padding: 0 !important;
      }
    }
  }

  .container {
    max-width: unset;
    margin-block: 1.25rem;
  }
}
