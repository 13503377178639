@use '../../../../styles/colors.scss';

#userAccountMenu {
  .userInfo {
    padding-inline: 0.25rem;
    padding-bottom: 0.65rem;
    padding-top: 0.35rem;
  }

  .menuItem {
    margin-inline: 0;
    margin-block: 0.5rem;
    border-radius: 0.35rem;
    padding-inline: 0.5rem;
    padding-block: 0.5rem;

    &.logoutMenu {
      margin-bottom: 0;
      &:hover {
        background-color: colors.$errorLight;
      }
    }
  }
}
