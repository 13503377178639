@use '../../../styles/colors.scss';

$dotSize: 0.5rem;

#dotLoader {
  position: relative;
  width: $dotSize;
  height: $dotSize;
  border-radius: 0.5rem;
  background-color: colors.$primaryMain;
  color: colors.$primaryMain;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: $dotSize;
    height: $dotSize;
    border-radius: 0.5rem;
    background-color: colors.$primaryMain;
    color: colors.$primaryMain;
    animation: dot-flashing 1s infinite alternate;
  }

  &::before {
    left: -0.8rem;
    animation-delay: 0s;
  }

  &::after {
    left: 0.8rem;
    animation-delay: 1s;
  }
}

@keyframes dot-flashing {
  0% {
    background-color: colors.$primaryMain;
  }
  50%,
  100% {
    background-color: colors.$primaryLight;
  }
}
