#loadingButton {
  position: relative;
  overflow: hidden;

  .loaderContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    &.loaderContainerWithIcon {
      margin-left: 12px;
    }
  }
}
