@use "../../../../styles/colors";

#conversation {
  margin-right: 1rem;

  .loadMessagesContainer {
    text-align: center;
    padding: 0.4rem;

    .loadMoreButton {
      color: colors.$primaryMain;
      cursor: pointer;
      font-size: 16px;
    }
  }
}