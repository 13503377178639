#instantSearchHeader {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.1rem 1.2rem;
  justify-content: center;
  .searchFormControl {
    padding-block: 1rem;
    font-size: 1.2rem;
  }
  .escBtn {
    padding: 0.25rem 0.75rem;
    min-width: unset;
    line-height: 1.2;
  }
}
