#casaAIHeader {
  display: grid;
  grid-template-columns: 20% auto 1fr;
  align-items: center;

  .createSessionDivider,
  .menuDivider {
    height: 60%;
    width: 1px;
    margin-block: auto;
  }

  .menuDivider {
    margin-right: 0.5rem;
  }

  .createSessionDivider {
    margin-left: 0.5rem;
  }

  .chatHistory {
    min-width: 3.5rem;
    text-align: center;
    margin: auto;
  }
}
