.message {
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 1rem;
  margin-bottom: 1.2rem;
  .loader {
    margin-left: 1rem;
    margin-top: 0.35rem;
  }
  .messageEntity {
    display: grid;
    gap: 0.8rem;
  }
}
