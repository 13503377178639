.feedback {
  .newFeedback {
    display: flex;
    align-items: flex-start;
    gap: 0.4rem;
    margin-bottom: 1rem;
  }

  .iconSize {
    font-size: 18px;
  }
  .clickable {
    cursor: pointer;
  }
}
