#memberReportConfigContainer {
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  .memberReportConfigTabs {
    height: fit-content;
    position: sticky;
    top: 5rem;
  }
}
