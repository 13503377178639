@use '../../../../../../styles/colors.scss';

.hierarchyCardContainer {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 12rem;
  height: 100%;
  overflow-y: scroll;
}
