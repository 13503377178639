#errorBoundary {
  .errorBoundaryBody {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;

    .errorImage {
      width: 100%;
      max-width: 350px;
      aspect-ratio: 1 / 1;
    }

    .errorBoundaryInfo {
      .errorBoundaryInfoTitle {
        margin-bottom: 0.25rem;
      }
      .errorBoundaryInfoDescription {
        margin-bottom: 2.5rem;
      }
    }
  }
}
