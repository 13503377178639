#casaAIInfo {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  .about {
    display: grid;
    gap: 1rem;
    text-align: center;
    justify-items: center;
    margin: 2rem;
    margin-top: 3rem;
  }
}
