@use "../../../../styles/colors";
.history{
  border-width: 0;
  border-style: solid;
  border-color: colors.$grey200;
  border-bottom-width: 0;
  height: 100%;
  overflow-y: auto;
  margin: 0.2rem;
  border-right-width: thin;

  .groupedItemContainer {
    margin: 1rem 0rem 1rem 1rem;
    .sessionItemContainer {
      width: 90%; /* Adjust width as needed */
      overflow: hidden;
      cursor: pointer;
      margin: 0.5rem 0rem;
      //position: relative;


      .fadeText {
        white-space: nowrap;
        overflow: hidden;
        margin: 0;
        font-size: 0.775rem;
        font-weight: 400;
        line-height: 1.5;
      }

      .fadeGradient {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30px;
        height: 100%;
        background-color: colors.$grey200;
      }
    }
    .gridContainerFaded {
      display: grid;
      grid-template-columns: 85% 10%;
    }

    .selectedSession {
      text-decoration: underline;
      color: colors.$primaryMain;
    }
  }
}
