@use '../../../../styles/colors.scss';

#sidebarList {
  &.parentElement {
    margin: 0 0.5rem;
  }

  &:not(&.parentElement) {
    margin-left: 1.7rem;
    padding-block: 0;
    position: relative;
    padding-left: .5rem;
    &::after {
      position: absolute;
      height: 90%;
      width: 1px;
      content: '';
      background-color: colors.$grey300;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
